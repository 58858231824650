import React, {useState} from "react";
import {
  Box,
  CircularProgress, Grid, MenuItem,
  Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as _ from "lodash";
import AscendAutocomplete from "../../../../../../shared/AscendAutocomplete";
import TextInput from "../../../../../../shared/TextInput";
import {ScrollbarList} from "../../../../../../shared/ScrollbarList";
import {AddressSelectionModal} from "./ui/AddressSelectionModal";
import {DoctorAddressViewOutput, DoctorViewOutput} from "../../../../../../enrollment-types";
import {DoctorForm} from "./ui/DoctorForm";
import {DoctorLine} from "./ui/DoctorLine";
import {useDoctors} from "./hooks/useDoctors";
import ChipWrapper from "../../../../../../shared/ChipWrapper";
import {ZipSelector} from "../../../../../../features";
import FormSelect from "../../../../../../shared/FormSelect";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    width: 90
  },
  title: {
    fontWeight: 500,
  },
  loadingIcon: {
    width: '20px!important',
    height: '20px!important',
  },
  lineWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px 16px 8px 20px',
    borderBottom: '1px solid rgba(28, 67, 79, 0.12)',
    marginBottom: 8,
    background: 'white',
    border: '1px solid #CCD8DC',
    borderRadius: 4,
  },
});

type DoctorSelectionProps = {
  zip: string,
  county: string,
  selectedDoctors: DoctorViewOutput[],
  onChange: (doctors: DoctorViewOutput[]) => Promise<void>
}

export const DoctorSelection = ({
                                  zip,
                                  county,
                                  selectedDoctors,
                                  onChange
                                }: DoctorSelectionProps) => {
  const classes = useStyles();
  const [showAutocomplete, setShowAutocomplete] = React.useState(false);
  const [autocompleteValue, setAutocompleteValue] = React.useState<string>('');
  const [location, setLocation] = useState<{zip: string, county: string}>({zip, county})
  const [radius, setRadius] = useState(30)

  const [open, setOpen] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [doctor, setDoctor] = React.useState<DoctorViewOutput>();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [showForm, setShowForm] = React.useState<number>();

  const {data, loading} = useDoctors({zipCode: location.zip, searchTerm: autocompleteValue, radius})

  const [addresses, setAddresses] = useState<DoctorAddressViewOutput[]>([])

  React.useEffect(() => {
    if (selectedDoctors.length) {
      setShowAutocomplete(false);
    }
  }, [selectedDoctors]);

  React.useEffect(() => {
    if (inputRef.current && showAutocomplete) {
      (inputRef.current as any).focus();
    }
  }, [showAutocomplete, inputRef.current])

  const onModalClose = async (addresses?: DoctorAddressViewOutput[]) => {
    setModalOpen(false);
    if (addresses && doctor) {
      await onChange([
        ..._.differenceWith(selectedDoctors, [doctor], (v1, v2) => v1.npi === v2.npi),
        {
          ...doctor as DoctorViewOutput,
          addresses,
        }])
    } else {
      setDoctor(undefined);
    }
    setAddresses([])
  }

  const onDeleteClick = (doctor: DoctorViewOutput) => {
    onChange(_.without(selectedDoctors, doctor));
  }

  const onEditClick = (index: number) => {
    setShowForm(index);
  }

  const onSaveClick = (doctor: DoctorViewOutput) => {
    if (doctor) {
      onChange([
        ..._.differenceWith(selectedDoctors, [doctor], (v1, v2) => v1.npi === v2.npi), doctor
      ]);
    }
    setShowForm(undefined);
  }

  const autocompleteOpen = !!autocompleteValue && !loading && !!data.length && open

  const doctorSelectHandle = (doctor: DoctorViewOutput) => {
    if (doctor.addressesNumber > 1) {
      setModalOpen(true)
      setDoctor({...doctor, addresses: []});
      setAddresses(doctor.addresses)
    } else {
      onChange([...selectedDoctors, doctor as DoctorViewOutput]);
      setDoctor(undefined);
      setAddresses([])
    }
    setAutocompleteValue('');
    setShowAutocomplete(false);
  }

  return <>
    <AddressSelectionModal open={modalOpen} doctor={doctor} onClose={onModalClose} addresses={addresses}/>

    {selectedDoctors.map((doctor, index) => (
      <Box key={doctor.npi} display={'flex'} className={'mb-10'}>
        <Typography variant={'body1'} color={'textPrimary'} className={`w-15`}>{index + 1}.</Typography>
        {showForm !== index && <DoctorLine doctor={doctor} showPCP showDelete onEditClick={() => onEditClick(index)}
                                           onDeleteClick={() => onDeleteClick(doctor)}/>}
        {showForm === index &&
            <DoctorForm doctor={doctor} onCancel={() => setShowForm(undefined)} onSaveClick={onSaveClick}/>}
      </Box>
    ))}

    <div className={'h-16'}/>
    {showAutocomplete && <ChipWrapper title={'Add Doctor'} >
        <IconButton size={'small'}
                    onClick={() => setShowAutocomplete(false)}
                    sx={{position: 'absolute', right: -16, top: -16, backgroundColor: '#FFF'}}>
            <Close />
        </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ZipSelector zip={location.zip}
                       county={location.county}
                       onChange={setLocation}
                       sx={{'& > div': {mb: 0} }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect label={'Radius'} onChange={e => setRadius(e.target.value as number)} value={radius} className={'mb-0'}>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={200}>200</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </FormSelect>
        </Grid>

        <Grid item xs={12}>
          <AscendAutocomplete fullWidth
                              open={!!autocompleteOpen}
                              onClose={(e, reason) => {
                                if (reason === 'blur' && (e as any).relatedTarget?.classList.contains('doctor-address-popover')) {
                                  return;
                                }
                                setTimeout(() => setOpen(false), 300) // todo: hack - fix closing autocomplete by blur
                              }}
                              onFocus={() => setOpen(true)}
                              options={data.filter(doctor =>
                                !selectedDoctors.some(d => d.npi === doctor.npi))
                              }
                              value={autocompleteValue}
                              onInputChange={(event, newInputValue) => {
                                setAutocompleteValue(newInputValue);
                              }}
                              sx={{
                                '& > div': {
                                  mb: 0
                                }
                              }}
                              clearOnBlur
                              filterOptions={(options) => options}
                              popupIcon={loading ?
                                <CircularProgress className={classes.loadingIcon}/> : undefined}
                              renderOption={(option, doctor: any) => (
                                <Box sx={{'&:hover': {background: '#eee'}, cursor: 'pointer', p: 1}}
                                     onClick={() => {
                                       doctorSelectHandle(doctor);
                                     }}>
                                  <DoctorLine doctor={doctor} onPopupClose={() => {
                                    setTimeout(() => inputRef.current?.focus(), 100)
                                  }}/>
                                </Box>
                              )}
                              id={'doctor-search-input'}
                              ListboxComponent={ScrollbarList as any}
                              renderInput={params => <TextInput inputRef={inputRef}
                                                                placeholder={'Search doctor name'}
                                                                {...params} />}/>
        </Grid>
      </Grid>
    </ChipWrapper>}
    {!showAutocomplete && <Typography className={'dark-green pointer'}
                                      variant={'body2'}
                                      onClick={() => setShowAutocomplete(true)}>
        <img src={'/img/add-icon.svg'}/> Add Doctor
    </Typography>}
  </>
}

