import React from "react";
import Card from "../../../../shared/Card";
import {
  Typography
} from "@mui/material";
import {DoctorSelection} from "./ui/DoctorSelection";
import {
  MedicareQuoteFilterExtraBenefit,
  useCreateMedicareQuoteForClientMutation,
  useSaveMedicareQuoteMutation
} from "../../../../types";
import {ClientViewOutput, DoctorViewOutput} from "../../../../enrollment-types";
import {GraphQLErrorType} from "../../../../Apollo/EnrollmentApolloClient";
import {enqueueSnackbar} from "notistack";
import {client as apolloClient} from "../../../../Apollo/ApolloClient";


interface UserPrioritiesProps {
  client?: ClientViewOutput
}

const Doctors = ({client}: UserPrioritiesProps) => {
  const [selectedDoctors, setSelectedDoctors] = React.useState<DoctorViewOutput[]>([]);
  const [createQuote] = useCreateMedicareQuoteForClientMutation({client: apolloClient});
  const [save] = useSaveMedicareQuoteMutation({
    client: apolloClient,
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.NO_CONNECTION:
          text = "Service is not available";
          break;
        default:
          text = "Unknown error"
      }
      enqueueSnackbar(text, {variant: "error"});
    },
    onCompleted: () => {
      enqueueSnackbar('Changes saved!', {variant: "success"});
    }
  });

  React.useEffect(() => {
    if (client?.doctors) {
      setSelectedDoctors(client?.doctors)
    }
  }, [client?.doctors]);

  const onDoctorsChange = async (doctors: DoctorViewOutput[]) => {
    if (client) {
      let quoteId = client.quoteId;
      if (!quoteId) {
        const result = await createQuote({
          variables: {
            clientId: client.id,
            data: {
              county: client.countyName,
              zip: client.zip,
              filters: {
                extraBenefits: [MedicareQuoteFilterExtraBenefit.DrugCoverage],
              },
            }
          }
        });
        quoteId = result.data?.createMedicareQuoteForClient.maQuoteID;
      }
      await save({variables: {
          data: {
            id: quoteId,
            zip: client.zip,
            county: client.countyName,
            preferredDoctors: doctors.map(d => ({
              npi: d.npi,
              addresses: d.addresses.map(a => ({addressId: a.id}))
            }))
          }
        }})
    }
  }

  return <Card>
    <Typography variant={'h4'} className={'mb-5'}>Doctors:</Typography>
    {client && (
      <DoctorSelection zip={client?.zip || ''}
                       county={client?.countyName || ''}
                       selectedDoctors={selectedDoctors}
                       onChange={onDoctorsChange} />
    )}
  </Card>;
}

export default Doctors;

