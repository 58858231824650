import {DoctorAddressViewOutput, DoctorViewOutput} from "../../../../../../../enrollment-types";
import React from "react";
import {AddressSelectionModal} from "./AddressSelectionModal";
import {Box, Button, styled, Theme, Typography} from "@mui/material";
import TextInput from "../../../../../../../shared/TextInput";

type DoctorFormProps = {
  doctor: DoctorViewOutput,
  onCancel: () => void,
  onSaveClick: (doctor: DoctorViewOutput) => void,
}


export const DoctorForm = (props: DoctorFormProps) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [doctor, setDoctor] = React.useState(props.doctor);

  const onModalClose = (addresses?: DoctorAddressViewOutput[]) => {
    if (addresses) {
      setDoctor({
        ...doctor as DoctorViewOutput,
        addresses,
      });
    }
    setModalOpen(false);
  }

  return <div className={'fill-width'}>
    <AddressSelectionModal open={modalOpen} doctor={props.doctor} onClose={onModalClose}  />
    <Wrapper>
      <Box flex={'1 1 50%'} overflow={'hidden'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
        <Typography variant={'body1'} color={'textPrimary'}>{doctor.name}</Typography>
        <TextInput
          disabled
          className={'mt-10'}
          fullWidth
          name="pcp"
          label="PCP"
          value={doctor.pcpDetails ? doctor.pcpDetails[0]?.pcpId : ''}
          onChange={(e) => {
            e.persist();
            setDoctor(prev => ({
              ...prev,
              pcpDetails: [{networkDetails: [{carrierName: '', name: ''}], pcpId: e.target?.value, id: ''}]
            }))
          }}
        />
      </Box>
      {!!doctor.addresses.length && <Box flex={'1 1 50%'} overflow={'hidden'} className={'ml-8'}>
        <Typography noWrap align={'right'} color={'textSecondary'}>{doctor.addresses[0].specialty}</Typography>
        <Typography color={'textSecondary'} noWrap align={'right'}>
          {doctor.addresses[0].addressLine1}
          {doctor.addresses[0].addressLine2}
        </Typography>
        {doctor.addresses.length > 0 && (
          <Typography className={'dark-green pointer fs-12 lh-16 mt-10'}
                      align={'right'}
                      onClick={() => setModalOpen(true)}>
            Change office
          </Typography>
        )}
      </Box>}
    </Wrapper>
    <Box sx={{display: 'flex', gap: 2}}>
      <Button variant={'outlined'} onClick={props.onCancel}>CANCEL</Button>
      <Button variant={'contained'} onClick={() => props.onSaveClick(doctor)} color={'primary'}>SAVE</Button>
    </Box>
  </div>
}

const Wrapper = styled(Box)(({theme}) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))